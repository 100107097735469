import React from "react";

import { Container, Row, Col } from "reactstrap"

// import bethany_russo from  "../../../static/images/2/howitworks/bethany_russo.jpg"
// import elliot_thomas from  "../../../static/images/2/howitworks/elliot_thomas.jpg"


import Accordian from "./accordian";
import AOS from 'aos';
// import useTranslations from "../useTranslations";


const Expert = () => {
    React.useEffect(() => {
        AOS.init();
    },[]);
    // const { global } = useTranslations();
  return (
    <Accordian mobileOnly data={{
        title:"3. Marketing Expert",
        desc:"We provide you with a dedicated marketing expert. They will be on hand to guide you and ensure"
    }}>
        <div className="section_inner">
        <Container>
            <Row>
                <Col lg={{ size: 5, offset: 0 }} md={{ size: 12, offset: 0 }}>
                    <h2 className="h1 color_white mb-4">Guidance when you need it</h2>
                    <p>We provide you with your own dedicated brillea expert. They will be on hand to guide you and ensure you get the best from brillea.</p>
                    <p>They will setup, run and manage your campaigns on Facebook, Instagram and Google Search using our brillea process &amp; growth framework.</p>
                    <p className="mb-4">Your expert will check in each month to go through results, insights and give recommendations on any changes to be made for the next month.</p>
                </Col>
                <Col>
                   <Row>
                       <Col md={{ size: 6 }}>
                            {/* <div className="iconCircle_expertwrapper mb-3">
                                <div className="iconCircle iconCircle_yellow"><FontAwesomeIcon icon={faGraduationCap} /></div>
                                <p>Our team of {global.country} based experts have been hand-picked and trained to the highest standard on the brillea process.</p>
                            </div> */}
                            <div className="expertsCard expertsCard_green" data-aos={"fade-up"}>
                                <p>
                                    Facebook<br/>
                                    Instagram <br/>
                                    &amp; Google <br/>
                                    Search ads <br/>
                                    masters
                                </p>
                            </div>
                       </Col>
                       <Col md={{ size: 6 }}>
                            <div className="expertsCard expertsCard_yellow" data-aos={"fade-up"} data-aos-delay={"400"}>
                                <p>
                                    Copywriting <br/>
                                    clever clogs
                                </p>
                            </div>
                            <div className="expertsCard expertsCard_orange" data-aos={"fade-up"} data-aos-delay={"800"}>
                                <p>
                                    Results <br/>
                                    driven game <br/>
                                    changers
                                </p>
                            </div>
                       </Col>
                   </Row>



                </Col>
            </Row>
        </Container>
        </div>
    </Accordian>
  )
}
export default Expert