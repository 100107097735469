import React, {useState} from "react"
import { Collapse } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const Accordian = ({ data, mobileOnly, children }) => {
  const {
    title,
    desc
  } = data

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
//isOpen
  return (
    <div className="Accordian__wrapper">
      <div className={mobileOnly ? "FaqItem Accordian Accordian_mobileOnly" : "FaqItem Accordian"} onClick={toggle} onKeyDown={toggle} role="presentation">
          <div className="FaqItem__title" role="presentation" >
            <h4>{title}</h4>
            <div className={(!isOpen ? "FaqItem__title_icon" : "FaqItem__title_icon FaqItem__title_icon-open")}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
          <div className="FaqItem__desc" data-isopen={isOpen} >
            <p>{desc}...</p>
          </div>
          {/* <Button className="FaqItem__read" onClick={toggle} color="link">
              Read {(isOpen ? "less" : "more")}
          </Button> */}
      </div>
          <Collapse isOpen={isOpen}>
            <div className="FaqItem__content">
                {children}
            </div>
          </Collapse>
      </div>
  )
}
export default Accordian