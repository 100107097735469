import React from "react";

import { Container, Row, Col } from "reactstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faChartBar, faList, faInfoCircle, faDollarSign, faPoundSign, faSitemap, faEdit, faUser } from '@fortawesome/free-solid-svg-icons'

import brillea_dashboard_lines from "../../../static/images/2/howitworks/brillea_dashboard_lines.svg";

import Accordian from "./accordian";
import AOS from 'aos';
import localeContext from "../../utils/localeContext";

const Insights = () => {
    React.useEffect(() => {
        AOS.init();
    },[]);
    const { locale } = React.useContext(localeContext)
  return (
<Accordian mobileOnly data={{
        title:"9. Everything at your fingertips",
        desc:"The dashboard is your gateway to brillea and provides everything you need to know about your ads"
    }}>
        <div className="section_inner">
        <Container>
            <Row>
                <Col md={{size:6}} sm={{size:12}}>
                    <h2 className="h1 color_purple_light mb-4">Everything at your fingertips</h2>
                    <p>The dashboard is your gateway to brillea and provides everything you need to know about your ads and access to your marketing expert.</p>
                </Col>
            </Row>

            <Row className="insights_bullet_wrapper">
                <Col lg={{size:8}} md={{size:10}}  sm={{size:12}}>
                    <ul className="col_2 results_list mt-3">
                        <li><FontAwesomeIcon icon={faChartPie} /> <strong>Results</strong></li>
                        <li><FontAwesomeIcon icon={faChartBar} /> <strong>Insight summaries</strong></li>
                        <li><FontAwesomeIcon icon={faSitemap} /> <strong>What campaigns are live</strong></li>
                        <li>{(locale === 'en-US' ? (<FontAwesomeIcon icon={faDollarSign} />) : (<FontAwesomeIcon icon={faPoundSign} />) )}   <strong>Spend levels &amp; targets</strong></li> 
                        <li><FontAwesomeIcon icon={faEdit} /> <strong>Your brief &amp; objectives</strong></li>
                        <li><FontAwesomeIcon icon={faList} /> <strong>Activity log</strong></li>
                        <li><FontAwesomeIcon icon={faInfoCircle} /> <strong>Help centre</strong></li>
                        <li><FontAwesomeIcon icon={faUser} /> <strong>Access to your marketing expert</strong></li>
                    </ul>
                </Col>
                <div className="section_insights_img" data-aos={"fade-left"} data-aos-delay={"400"}>
                    <img src={brillea_dashboard_lines} alt="Brillea Digital Marketing Dashboard" />
                </div>
            </Row>
        </Container>
        </div>
    </Accordian>
  )
}
export default Insights