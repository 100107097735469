import React from "react";

import { Container, Row, Col, Button } from "reactstrap"
import {Link} from "gatsby";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

import Facebook_Platform_Mockup from "../../../static/images/2/howitworks/Facebook_Platform_Mockup.jpg";
import Google_Ads_Platform_Mockup from "../../../static/images/2/howitworks/Google_Ads_Platform_Mockup.jpg";

import google_logo from "../../../static/images/2/logos/platform/google_ads_logo.png";
import facebook_logo from "../../../static/images/2/logos/platform/facebook_logo.png";

import setup_suzie from "../../../static/images/2/howitworks/setup_suzie.jpg";
import setup_lines from "../../../static/images/2/howitworks/setup_lines.svg";

import Accordian from "./accordian";
import AOS from 'aos';
import useTranslations from "../useTranslations";
import { ClientLogoList } from "../ClientLogoList";

import logoWoo from '../../../static/images/2/logos/Ecom/grey/woo_logo.svg';
import logoShopify from '../../../static/images/2/logos/Ecom/grey/shopify_logo.svg';
import logoSquarespace from '../../../static/images/2/logos/Ecom/grey/squarespace_logo.png';
import logoWix from '../../../static/images/2/logos/Ecom/grey/wix_logo.svg';
import logoMagento from '../../../static/images/2/logos/Ecom/grey/magento_logo.svg';

const Setup = () => {
    React.useEffect(() => {
        AOS.init();
    },[]);
    const { global } = useTranslations();
  return (
    <Accordian mobileOnly data={{
        title:"2. Tech set Up",
        desc:"To start your growth journey, you'll need to give brillea access to your ad accounts and setup your analytics."
    }}>
        <div className="section_inner">
        <Container>
            <Row>
                <Col lg={{ size: 5 }} md={{ size: 12 }}>
                    <h2 className="h1 mb-4 purple_light_color">Tech set up</h2>
                    <p>You'll need to give us access to your Facebook &amp; Google ad accounts and connect your product catalogue through Shopify or other eCommerce platform.</p>
                    <p>We will provide a one-off setup of your Facebook pixel and Google tracking so we can accurately measure &amp; {global.optimise} your ads based on the correct conversion points.</p>
                    <p>We'll help and guide you though every step of the process.</p>
                    <Button to={"/help/"} color="link" size="lg" className="btn-link-purple" tag={Link}>Read the guides <FontAwesomeIcon icon={faArrowCircleRight}/></Button>
                </Col>
                <Col>
                    <div className="setup_img">
                        <div className="setup_img__platform_ga" data-aos={"fade-up"}>
                            <img className="google_dash" src={Google_Ads_Platform_Mockup} alt="Brillea Google Ads Marketing Platform"/>
                            <img className="setup_img__platform_ga_logo" src={google_logo} alt="brillea helps me set up google ads"/>
                        </div>
                        <div className="setup_img__platform_fb" data-aos={"fade-up"} data-aos-delay={"300"}>
                            <img className="facebook_dash" src={Facebook_Platform_Mockup} alt="Brillea Facebook Ads Marketing Platform"/>
                            <img className="setup_img__platform_fb_logo" src={facebook_logo} alt="brillea helps me set up facebook ads"/>
                        </div>
                        <div className="setup_img__lines">
                            <img src={setup_lines} alt="brillea makes setup easy" data-aos={"fade-in"} data-aos-delay={"1000"}/>
                            <div className="setup_img__lines_profile" data-aos={"flip-left"} data-aos-delay={"1200"}>
                                <img src={setup_suzie} alt="brillea customer setup suzie"/>
                            </div>
                            <div className="setup_img__lines_profile_desc" data-aos={"fade-left"} data-aos-delay={"1400"}>
                                <p>Add your brillea manager to get set up</p>
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                
                <ClientLogoList
                        logos={[
                        { name: 'Squarespace', src: logoSquarespace },
                        { name: 'Wix', src: logoWix },
                        { name: 'Shopify', src: logoShopify },
                        { name: 'Woo-commerce', src: logoWoo },
                        { name: "Magento", src: logoMagento }
                        ]}
                        size={{ lg: '5', sm: '3' }}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    </Accordian>
  )
}
export default Setup;