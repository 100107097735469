import React from "react";
import { Container, Row, Col } from "reactstrap"


import icon_target from "../../../static/images/2/howitworks/icon_target.svg";
import lookalikes from "../../../static/images/2/howitworks/lookalikes.svg";
import icon_remarketing from "../../../static/images/2/howitworks/icon_remarketing.svg";


import Accordian from "./accordian";

import AOS from "aos";

const Audience = () => {
    React.useEffect(() => {
        AOS.init();
    },[]);
  return (
    <Accordian mobileOnly data={{
        title:"4. Finding your audience",
        desc:"Your marketing expert will help you discover more ways to achieve your marketing goals and grow your business"
    }}>
        <div className="section_inner">
        <Container>
            <Row>
                <Col md={{size:5}} sm={{size:12}}>
                    <h2 className="h1 color_purple_light mb-4">Target your future customers</h2>
                    <p>brillea will help you get more sales, leads and revenue by testing different target audiences based on remarketing, lookalikes and brand new target audiences and segments.</p>
                    <p>We guide you through the briefing process and then make targeting recommendations using the brillea approach to Facebook, Instagram and Google Search Ads.</p>
                </Col>
                <Col>
                    <div className="audience_cards_wrapper">
                    <div className="audience_cards">
                        <div className="audience_cards__item" data-aos={"zoom-in"}>
                            <img src={icon_target} alt="Brillea Digital Marketing Audiences"/>
                            <h3>New audiences</h3>
                            <p>Find new customers by exploring new audiences based on interests, behaviours and demographics</p>
                        </div>
                        <div className="audience_cards__item" data-aos={"zoom-in"} data-aos-delay={"600"}>
                            <img src={lookalikes} alt="Reach new people with lookalikes"/>
                            <h3>Lookalikes</h3>
                            <p>Reach new people who are similar to your best existing customers, demographic information or interests</p>
                        </div>
                        <div className="audience_cards__item" data-aos={"zoom-in"} data-aos-delay={"1200"}>
                            <img src={icon_remarketing} alt="Remarketing Campaigns"/>
                            <h3>Remarketing</h3>
                            <p>Show relevant ads to people who have recently visited your website</p>
                        </div>
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
    </Accordian>
  )
}
export default Audience