import React from "react";
import {Link} from "gatsby"
import { Container, Row, Col, Button } from "reactstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faCalculator, faCalendar, faStar } from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'


import poster_boy_instagram from "../../../static/images/2/howitworks/poster_boy_instagram.jpg";

import Accordian from "./accordian";
import AOS from 'aos';

const Spend = () => {
    React.useEffect(() => {
        AOS.init();
    },[]);
  return (
    <Accordian mobileOnly data={{
        title:"6. Marketing spend",
        desc:"At the start of each month we'll agree the maximum spend. If you're not sure how much to spend"
    }}>
        <div className="section_inner">
        <Container>
            <Row>
                <Col lg={{size:5}} md={{size:12}} >
                    <h2 className="h1 color_purple_light mb-4">Getting the most from your ad spend</h2>
                    <p>At the start of each month we'll agree the maximum spend. If you're not sure how much to spend, we can guide you on this.</p>
                    <p>brillea then optimises your spend by re-allocating budgets against different ads and target audiences. This ensures you get the best performance from brillea and maximum return on your ad spend.</p>

                </Col>
                <Col>
                    <div className="spend_wrapper">
                        <div className="spend_cards_instagram">

                            <div className="iconCircle_wrapper" data-aos={"fade-up"} data-aos-delay={"1500"}>
                                <div className="iconCircle iconCircle_green">
                                    <FontAwesomeIcon icon={faStar}/>
                                </div>
                                <p className="color_purple"><small><strong>Best performing</strong></small></p>
                            </div>

                            <img src={poster_boy_instagram} alt="poster boy instagram example"/>
                        </div>
                        <div className="spend_cards" data-aos={"fade-up"} data-aos-delay={"400"}>
                            <div className="spend_cards__item last_month bk-white">
                                <table>
                                    <thead>
                                        <tr className="head">
                                            <td><FontAwesomeIcon icon={faCalendar} /> <h4>Last month</h4></td>
                                            <td className="table_figure"><FontAwesomeIcon icon={faGoogle} /></td>
                                            <td className="hightlight table_figure"><FontAwesomeIcon icon={faFacebook} /></td>
                                            <td className="table_figure"><FontAwesomeIcon icon={faInstagram}  /></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p>Budget Allocated</p></td>
                                            <td className="table_figure">50%</td>
                                            <td className="hightlight table_figure">20%</td>
                                            <td className="table_figure">30%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="spend_cards__item bk-purple-light" data-aos={"fade-up"} data-aos-delay={"800"}>
                                <p>Based on last month's results, where Facebook ads performed best, we suggests you allocate more budget to Facebook:</p>
                                <table>
                                    <thead>
                                        <tr className="head">
                                            <td><FontAwesomeIcon icon={faCalendar} /> <h4>This month</h4></td>
                                            <td className="table_figure"><FontAwesomeIcon icon={faGoogle} /></td>
                                            <td className="hightlight table_figure"><FontAwesomeIcon icon={faFacebook} /></td>
                                            <td className="table_figure"><FontAwesomeIcon icon={faInstagram}  /></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p>Suggested budget</p></td>
                                            <td className="table_figure">35%</td>
                                            <td className="hightlight table_figure">65%</td>
                                            <td className="table_figure">0%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="iconCircle_wrapper display-flex mt-3">
                        <div className="iconCircle iconCircle_yellow">
                            <FontAwesomeIcon icon={faCalculator}/>
                        </div>
                        <p className="ml-3 mb-0">Not sure what you need <br/>to spend to get results?<br/>
                        <Button to={"/pricing/#spend_calculator"} color="link"  className="btn-link-purple mt-0" tag={Link}>try our ROI calculator <FontAwesomeIcon icon={faArrowCircleRight}/></Button>
                        </p>
                    </div>
                        


                </Col>
            </Row>
        </Container>
        </div>
    </Accordian>
  )
}
export default Spend;