import React from "react";

import { Container, Row, Col } from "reactstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTag, faImage, faEdit, faStar, faUsers  } from '@fortawesome/free-solid-svg-icons'

import brillea_ads from "../../../static/images/2/howitworks/results_mob_preview.png"
import brillea_ads_us from "../../../static/images/2/howitworks/results_mob_preview_en-US.png"
import elliot_thomas from  "../../../static/images/2/howitworks/elliot_thomas.jpg"

import Accordian from "./accordian";
import AOS from 'aos';
import localeContext from "../../utils/localeContext";

const Reporting = () => {
    React.useEffect(() => {
        AOS.init();
    },[]);
    const { locale } = React.useContext(localeContext);
  return (
<Accordian mobileOnly data={{
        title:"7. Improving your results",
        desc:"We're committed to improving your results, so each month we introduce up to 4 new tests"
    }}>
        <div className="section_inner">
        <Container>
            <Row>
                <Col>
                    <h2 className="h1 color_white mb-4">Continuous commitment to improving your ad performance</h2>
                </Col>
            </Row>
            <Row>
                <Col md={{size:6}} sm={{size:12}}>
                    <p className="color_white">brillea continuously looks for ways to improve the return on your ad spend, so each month we introduce up to 4 new tests. These could be variations of:</p>
                    <ul className="pill_list mb-3">
                        <li><p data-aos={"fade-right"}>target audiences <FontAwesomeIcon icon={faEye} /></p></li>
                        <li><p data-aos={"fade-right"} data-aos-delay={"300"}>offers and discounts <FontAwesomeIcon icon={faTag} /></p></li>
                        <li><p data-aos={"fade-right"} data-aos-delay={"600"}>New ad creative &amp; design <FontAwesomeIcon icon={faImage} /></p></li>
                        <li><p data-aos={"fade-right"} data-aos-delay={"900"}>Copy testing <FontAwesomeIcon icon={faEdit} /></p></li>
                    </ul>
                    <p className="color_white">While the ads are live, brillea will optimise in a few ways including, but not limited to;</p>
                    <ul className="color_white">
                        <li><p>Allocating spend to better <br className="hidden-sm"/>performing audiences</p></li>
                        <li><p>Reducing spend on ads <br className="hidden-sm"/>that aren't working</p></li>
                        <li><p>Pausing ads if people begin <br className="hidden-sm"/>to see them too often</p></li>
                    </ul>
                </Col>
            </Row>
            </Container>

            <div className="results_img">
                    <div className="results_winner" data-aos={"fade-right"} data-aos-delay={"1200"}>
                        <div className="iconCircle iconCircle_green">
                            <FontAwesomeIcon icon={faStar}/>
                        </div>
                        <p className="color_white mb-0"><small>Best converting ad</small></p>
                    </div>
                    <div className="results_img_main" data-aos={"zoom-in-right"}>
                        <img src={(locale === 'en-US' ? brillea_ads_us : brillea_ads)} alt="brillea Ads" />
                    </div>
                    <div className="results_quote">
                        <div className="results_quote_wrapper" data-aos={"zoom-in-left"} data-aos-delay={"900"}>
                            <h5>THIS OFFER IS PERFORMING WELL</h5>
                            <div className="results_quote_wrapper_content">
                                <FontAwesomeIcon icon={faUsers}/>
                                <p>Woah! Your audience is loving the 10% Off offer. You could reach your goal if you run more ads like this.</p>
                            </div>
                        </div>
                        <div className="results_quote_profile" data-aos={"zoom-in-left"}>
                            <img src={elliot_thomas} alt={"brillea marketing expert profile"}/>
                        </div>
                    </div>
                </div>

        </div>
    </Accordian>
  )
}
export default Reporting