import React, { useRef, useEffect, useState } from "react";
import {Link} from "gatsby";

import AOS from 'aos';
import { Container, Row, Col, Button } from "reactstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faPoundSign, faDollarSign, faUser, faShoppingCart, faImage } from '@fortawesome/free-solid-svg-icons'

import { Sticky, StickyContainer } from "react-sticky";

import Accordian from "./accordian";


import Insights from "./insights";
import Setup from "./setup";
import Reporting from "./reporting";
import Results from "./results";
import Spend from "./spend";
import Audience from "./audience";
import Expert from "./expert";
// import Ads from "./ads";  
import localeContext from "../../utils/localeContext";

export const HowItWorksSections = () => {


    const [visibleSection, setVisibleSection] = useState(false);

    const goalsRef = useRef(null);
    const setupRef = useRef(null);
    const expertRef = useRef(null);
    const audienceRef = useRef(null);
    const adsRef = useRef(null);
    const spendRef = useRef(null);
    const resultsRef = useRef(null);
    const reportingRef = useRef(null);
    const insightsRef = useRef(null);

    const getDimensions = ele => {
        const { height } = ele.getBoundingClientRect();
        const offsetTop = ele.offsetTop;
        const offsetBottom = offsetTop + height;
      
        return {
          height,
          offsetTop,
          offsetBottom,
        };
      };
      
      const scrollTo = ele => {
        ele.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      };

    useEffect(() => {
        AOS.init();
    },[]);
  
    useEffect(() => {
        const sectionRefs = [
            { section: "Goals", ref: goalsRef },
            { section: "Setup", ref: setupRef },
            { section: "Experts", ref: expertRef },
            { section: "Audience", ref: audienceRef },
            { section: "Ads", ref: adsRef },
            { section: "Spend", ref: spendRef },
            { section: "Results", ref: resultsRef },
            { section: "Reporting", ref: reportingRef },
            { section: "Insights", ref: insightsRef },
          ];  
      const handleScroll = () => {
        // const { height: headerHeight } = getDimensions(headerRef.current);
        const scrollPosition = window.scrollY;
  
        const selected = sectionRefs.find(({ ref }) => {
          const ele = ref.current;
          if (ele) {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
          return null;
        });
  
        if (selected && selected.section !== visibleSection) {
          setVisibleSection(selected.section);
        } else if (!selected && visibleSection) {
          setVisibleSection(false);
        }
      };
  
      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [visibleSection]);

    const { locale } = React.useContext(localeContext);

  return (
    <>
    <div className="section_medium">
        <Container>
            <Row>
                <Col>
                    <h1 className="text-center">Get better results for less</h1>
                    <h2 className="h3 text-center color-purple-light mt-4">Here's how brillea works</h2>
                </Col>
            </Row>
        </Container>
    </div>

    <StickyContainer className="howitworks_tiles">
    <div className="section section__goals" id="Goals" ref={goalsRef}>
    <Accordian mobileOnly data={{
        title:"1. Set your goals",
        desc:"The more we know about you, your brand, product, business and purpose, the smarter we can be with your marketing."
    }}>
        <div className="section_inner">

        <Container>

        <Sticky disableCompensation>
        {({ style }) => (
        <div className="sticky_nav" style={style} >
            <ol>
                <li>
                    <button 
                        className={`${visibleSection === "Goals" || visibleSection === false ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(goalsRef.current);
                        }}
                        >Set your goals</button>
                </li>
                <li>
                    <button 
                        className={`${visibleSection === "Setup" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(setupRef.current);
                        }}
                        >Tech set up</button>
                </li>
                <li>
                    <button 
                        className={`${visibleSection === "Experts" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(expertRef.current);
                        }}
                        >MARKETING EXPERT</button>
                </li>
                <li>
                    <button 
                        className={`${visibleSection === "Audience" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(audienceRef.current);
                        }}
                        >FINDING YOUR AUDIENCE</button>
                </li>
                {/* <li>
                    <button 
                        className={`${visibleSection === "Ads" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(adsRef.current);
                        }}
                        >AD creation</button>
                </li> */}
                <li>
                    <button 
                        className={`${visibleSection === "Spend" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(spendRef.current);
                        }}
                        >MARKETING SPEND</button>
                </li>
                <li>
                    <button 
                        className={`${visibleSection === "Results" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(resultsRef.current);
                        }}
                        >IMPROVING YOUR results</button>
                </li>
                <li>
                    <button 
                        className={`${visibleSection === "Reporting" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(reportingRef.current);
                        }}
                        >REPORTING</button>
                </li>
                <li>
                    <button 
                        className={`${visibleSection === "Insights" ? "selected" : ""}`}
                        onClick={() => {
                            scrollTo(insightsRef.current);
                        }}
                        >Your Dashboard</button>
                </li>
            </ol>
        </div>
        )}
    </Sticky>

            <Row>
                <Col md={{ size: 5, offset: 0 }}>
                    <h2 className="h1 mb-4 purple_light_color">Getting to <br/>know you</h2>
                    <p>The more we know about you, your brand, products, business and purpose, the smarter we can be with your marketing.</p>
                    <p>Our clever onboarding process ensures we have everything we need to help you grow and win.</p>
                    <Button to={"/pricing/"} color="link" size="lg" className="btn-link-purple" tag={Link}>Get Started <FontAwesomeIcon icon={faArrowCircleRight}/></Button>
                </Col>
                <Col md={{ size: 6, offset: 1 }}> 
                    <div className="goal_cards">

                        <div className="goal_cards__item" data-aos={"zoom-in-left"}>
                            <div className="goal_cards__icon">
                                {(locale === 'en-US' ? (<FontAwesomeIcon icon={faDollarSign} />) : (<FontAwesomeIcon icon={faPoundSign} />) )}
                            </div>
                            <div className="goal_cards__content">
                                <h3>Your brief</h3>
                                <p>To maximise sales and revenue</p>
                            </div>
                        </div>

                        <div className="goal_cards__item" data-aos={"zoom-in-left"}>
                            <div className="goal_cards__icon">
                                <FontAwesomeIcon icon={faImage} />
                            </div>
                            <div className="goal_cards__content">
                                <h3>Your brand</h3>
                                <p>Who you are, what you stand for and why you exist</p>
                            </div>
                        </div>

                        <div className="goal_cards__item" data-aos={"zoom-in-left"}>
                            <div className="goal_cards__icon">
                                <FontAwesomeIcon icon={faShoppingCart} />
                            </div>
                            <div className="goal_cards__content">
                                <h3>Your products</h3>
                                <p>The products you sell, your best sellers and what makes them unique</p>
                            </div>
                        </div>

                        <div className="goal_cards__item" data-aos={"zoom-in-left"}>
                            <div className="goal_cards__icon">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <div className="goal_cards__content">
                                <h3>Your customers</h3>
                                <p>Who your products are for and why</p>
                            </div>
                        </div>


                    </div>

                </Col>
            </Row>
        </Container>
        </div>
    </Accordian>
    </div>

    <div className="section section__setup" id="Setup" ref={setupRef}>
        <Setup />
    </div>

    <div className="section section__experts bk-purple-dark" id="Experts" ref={expertRef}>
        <Expert/> 
    </div>

    <div className="section section_audience" id="Audiences" ref={audienceRef}>
        <Audience/>
    </div>

    {/* <div className="section section_ads" id="Ads" ref={adsRef}>
        <Ads/>
    </div> */}

    <div className="section section_spend" id="Spend" ref={spendRef}>
        <Spend/>
    </div>

    <div className="section section_results bk-purple-dark" id="Results" ref={resultsRef}>
        <Results/>
    </div>

    <div className="section section_reporting bk-grey" id="Reporting" ref={reportingRef}>
        <Reporting />
    </div>

    <div className="section section_insights" id="Insights" ref={insightsRef}>
        <Insights />
    </div>

    </StickyContainer>
    </>
)};