import React from "react";
import SEO from "../components/SEO"
import {HowItWorksSections} from "../components/how-it-works/HowItWorksSections";

class HowItWorks extends React.Component {
    render() {
  return (
  <>
    <SEO 
      title="How brillea digital marketing works"
    />
    <HowItWorksSections />
  </>
)}};

export default HowItWorks;