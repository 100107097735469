import React from "react";
// import {Link} from "gatsby";

import { Container, Row, Col } from "reactstrap"

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'



import insights_report from "../../../static/images/2/howitworks/insights_report.jpg";
import improve_report from "../../../static/images/2/howitworks/improve_report.jpg";
import brillea_dashboard_resultsDetail from "../../../static/images/2/howitworks/brillea_dashboard_resultsDetail.png";

import Accordian from "./accordian";
import AOS from 'aos';

const Reporting = () => {
    React.useEffect(() => {
        AOS.init();
    },[]);
  return (
    <Accordian mobileOnly data={{
        title:"8. Reporting",
        desc:"Each month we'll provide you with an insight summary of learnings (e.g what channels, campaigns and"
    }}>
        <div className="section_inner">
        <Container> 
            <Row>
                <Col lg={{size:6}} md={{size:12}}>
                    <h2 className="h1 color_purple_light mb-4">Your results <br/>and insights</h2>
                    <p>See how your ads are performing in real time with our user friendly reporting Each month we'll provide you with an insight summary of learnings (e.g what channels, campaigns and tests performed well and which didn't) and recommendations for the following month</p>

                    {/* <p className="mt-2"><strong>Download an example of our reports</strong></p>
                    <Button to={"/pricing/"} color="link" size="lg" className="btn-link-purple" tag={Link}>Insights Report <FontAwesomeIcon icon={faArrowCircleDown}/></Button>
                    <br/>
                    <Button to={"/pricing/"} color="link" size="lg" className="btn-link-purple mb-3" tag={Link}>Improvement suggestions <FontAwesomeIcon icon={faArrowCircleDown}/></Button> */}
                </Col>
                <Col lg={{size:6}} md={{size:12}}>
                    <div className="report_wrapper_cont" style={{position:"relative"}}>
                        <div data-aos={"fade-up"}>
                            <img src={brillea_dashboard_resultsDetail} alt={"monthly digital marketing insights report"} />
                        </div>
                        <div className="report_wrapper" style={{position:"absolute", bottom:"-20px"}}>
                            <div className="insights_report" data-aos={"fade-up"}>
                                <img src={insights_report} alt={"monthly digital marketing insights report"} />
                            </div>
                            <div className="insights_report insights_report_suggestion" data-aos={"fade-up"} data-aos-delay={"300"}>
                                <img src={improve_report} alt={"monthly digital marketing improvement report"} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
    </Accordian>
  )
}
export default Reporting